import React from 'react';
import { H1 } from '../Atoms/Typography/Headings/Heading';
import Select from '../Shared/Input/Select';
import Pagination from '../Shared/Pagination/Pagination';
import { useTranslationData } from '../Shared/Providers/TranslationProvider';
import { styled } from '../stitches.config';
import XhtmlArea from '../Molecules/XHtmlArea/XHtmlArea';

type PropType = {
  heading: string;
  totalCount: number;
  introText: string;
  changePage: (event: any) => void;
  changePageSize: (value: any) => void;
  pageSize: number;
  pageIndex: number;
  children: React.ReactNode;
};

function OrderListing({
  totalCount,
  heading,
  introText,
  changePage,
  changePageSize,
  pageSize,
  pageIndex,
  children,
}: PropType) {
  const { 'orderHistoryPage/pageSize': pageSizeLabel } = useTranslationData();

  const pageCount = totalCount / pageSize;

  return (
    <OrderListingWrapper>
      {heading && (
        <Heading noMargin bottomMargin={!introText}>
          {heading}
        </Heading>
      )}
      {introText && <IntroText>{introText}</IntroText>}
      <Select
        selectedValue={pageSize}
        onSelect={changePageSize}
        label={pageSizeLabel}
        options={[
          {
            text: '5',
            value: 5,
          },
          {
            text: '10',
            value: 10,
          },
        ]}
      />
      {children}
      <Pagination
        totalRecords={totalCount}
        pageCount={pageCount}
        onPageChange={changePage}
        currentPage={pageIndex}
      />
    </OrderListingWrapper>
  );
}

const Heading = styled(H1, {
  fs: 11,
  fontWeight: '$fw300',
  lineHeight: '32px',
  mb: 2,
  '@mediaMinLarge': {
    fs: 13,
  },
  variants: {
    bottomMargin: {
      true: {
        mb: 4,
      },
    },
  },
});

const IntroText = styled('p', {});

const OrderListingWrapper = styled('div', {
  display: 'block',
  p: 4,

  maxW: 256,
  background: '$white',
  m: 4,
  '@mediaMinLarge': {
    w: '100%',
    mx: 'auto',
    my: 12,
    p: 8,
  },
});

export default OrderListing;
