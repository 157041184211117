import React from 'react';
import { styled } from '../../stitches.config';

type PropTypes = {
  isActive?: boolean;
  onClick?: any;
  children?: any;
};

function PaginationStep({ isActive, onClick, children }: PropTypes) {
  return (
    <Step {...(isActive && { isActive })} onClick={() => onClick()}>
      {children}
    </Step>
  );
}

const Step = styled('span', {
  mx: 6,
  color: '$grey800',
  '&:hover': {
    cursor: 'pointer',
    color: '$black',
  },
  variants: {
    isActive: {
      true: {
        fontWeight: '$fw700',
        color: '$black',
      },
    },
  },
});

export default React.memo(PaginationStep);
