import { useState, useRef } from 'react';
import ChevronIcon from '../../Atoms/Icons/ChevronIcon';
import { styled } from '../../stitches.config';

type PropTypes = {
  values: any[] | undefined;
  currentValue: string;
  setSelectedValue: (value: string) => void;
  isPagination?: boolean;
  label?: string;
  isValid?: boolean;
  returnIndex?: boolean;
  disabled?: boolean;
  defaultValue?: string;
};

function GenericDropDown({
  values,
  currentValue,
  setSelectedValue,
  isPagination = false,
  label,
  isValid = true,
  returnIndex = false,
  disabled = false,
  defaultValue,
}: PropTypes) {
  const [showValues, toggleValues] = useState(false);
  const ref = useRef(null);
  const paginationStyle = {
    mb: 0,
    h: '100%',
  };
  const updateValue = (value: string, index: string = '0') => {
    const newValue = index;
    setSelectedValue(value);
  };

  const dropDownStyles = {
    ...(!isValid && dropdownContainerInvalid),
    ...(isPagination && paginationStyle),
    ...(disabled && {
      border: {
        xy: {
          style: 'solid',
          w: 0.25,
          color: '$grey500',
        },
      },
    }),
  };

  const currentValueStyle = {
    ...(disabled && {
      color: '$grey500',
    }),
  };

  return (
    <Wrapper
      tabIndex={0}
      onBlur={(e: any) => {
        if (!e.currentTarget.contains(e.relatedTarget as Element)) {
          toggleValues(false);
        }
      }}
      css={isPagination ? { marginBottom: 0 } : {}}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <DropDownContainer css={dropDownStyles}>
        <ValueFlex
          onClick={() => !disabled && toggleValues((prev) => !prev)}
          ref={ref}
        >
          {isPagination ? (
            <CurrentValue>
              {currentValue} / {values?.length}
            </CurrentValue>
          ) : (
            <CurrentValue css={currentValueStyle}>
              {!!currentValue && !!defaultValue ? defaultValue : currentValue}{' '}
            </CurrentValue>
          )}
          <ArrowIconWrapper>
            <StyledArrow
              css={{
                transform: showValues ? 'rotate(180deg)' : 'none',
                marginRight: 0,
              }}
            />
          </ArrowIconWrapper>
        </ValueFlex>
        {showValues && (
          <DropDown css={showValues ? {} : { display: 'none' }}>
            {values &&
              values.length > 0 &&
              values.map((value: string, index: number) => (
                <DropDownButton
                  key={value + index}
                  onClick={() => {
                    updateValue(value, (index + 1).toString());
                    toggleValues(false);
                  }}
                  css={{
                    opacity: isPagination
                      ? value
                      : value === currentValue
                      ? 0.5
                      : 1,
                  }}
                >
                  {!isPagination ? value : index + 1}
                </DropDownButton>
              ))}
          </DropDown>
        )}
      </DropDownContainer>
    </Wrapper>
  );
}
export default GenericDropDown;

const Wrapper = styled('div', {
  mb: 4,
});

const DropDownButton = styled('button', {
  width: '-webkit-fill-available',
  h: '100%',
  fs: 6,
  textAlign: 'left',
  ':focus': {
    boxShadow: '0 0 0 2px #FFF',
  },
  mx: 4,
  my: 3,
});

const ArrowIconWrapper = styled('div', {
  transform: 'rotate(90deg)',
  mr: 2,
});

const DropDownContainer = styled('div', {
  w: '100%',
  position: 'relative',
  h: 'auto',
  display: 'block',
  textAlign: 'left',
  border: '1px solid $black',
});

const ValueFlex = styled('div', {
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  h: '100%',
  alignItems: 'center',
  cursor: 'pointer',
});

const DropDown = styled('div', {
  history: 'auto',
  maxW: 50,
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid $black',
  backgroundColor: 'white',
  position: 'absolute',
  w: '100%',
  zIndex: '$DropDown',
  overflow: 'auto',
});

const CurrentValue = styled('span', {
  fs: 8,
  lineHeight: '2px',
  color: '$black',
  mx: 4,
});

const StyledArrow = styled(ChevronIcon, {
  wh: 3,
  transitionDelay: '200ms',
  transitionProperty: 'all',
});

const FormLabel = styled('label', {
  fs: 7,
  fontWeight: '$fw700',
  position: 'relative',
  pb: 3,
  backgroundColor: 'inherit',
  display: 'block',
});

const dropdownContainerInvalid = {
  border: '1px solid $errorPrimary',
};
