import { useEffect, useState } from 'react';
import OrderDetailsModel from '../../Order/Models/OrderDetailsModel.interface';
import OrderInfoViewModel from '../../Order/Models/OrderInfoViewModel.interface';
import OrderHistoryPageModel from '../../OrderHistoryPage/Models/OrderHistoryPageModel.interface';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useUserStateData } from '../../Shared/UserContextProvider/UserContextProvider';
import { GetOrders } from '../../OrderComponent/Order';
import OrderListing from '../../OrderComponent/OrderListing';
import { getFormattedDate } from '../../Shared/Common/Helpers';
import Accordion from '../../Molecules/Accordion/Accordion';
import OrderRowListing from '../../OrderComponent/OrderRowListing';
import Dots from '../../Atoms/Loaders/Dots';
import Divider from '../../Atoms/Divider/Divider';
import { styled } from '../../stitches.config';
import KexLink from '../../Kex/KexLink';

function OrderHistoryPage() {
  const {
    translations: {
      'customerInfo/myOrders/noOrders': noOrdersLabel,
      'orderHistoryPage/dateString': dateLabel,
    },
    languageRoute,
    requestToken,
    staticPages: { orderDetailsPage },
  } = useAppSettingsData();

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const currentPage = useCurrentPage<OrderHistoryPageModel>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { customerId } = useUserStateData();

  const url = `/api/${
    languageRoute || 'en'
  }/order/GetOrderHistory?customerId=${customerId}`;
  const [orders, setOrders] = useState<OrderDetailsModel[]>();

  const fetchData = async (loadMoreUrl?: string) => {
    if (customerId) {
      let endpoint =
        loadMoreUrl || `${url}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
      const data: OrderInfoViewModel = await GetOrders(
        currentPage.channelId,
        endpoint,
        setIsLoading
      );

      if (data) {
        setOrders(data.orderHistory);
        setTotalCount(data.totalOrdersCount);
      }
    }
  };

  async function handleChangePage(value: any) {
    setPageIndex(value);

    const newUrl = `${url}&pageIndex=${value}&pageSize=${pageSize}`;

    fetchData(newUrl);
  }

  const handleChangePageSize = (value: any) => {
    setPageSize(value);
    const newUrl = `${url}&pageIndex=${pageIndex}&pageSize=${value}`;
    fetchData(newUrl);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage.channelId]);

  return (
    <main>
      {orders && !!orders.length ? (
        <>
          <OrderListing
            heading={currentPage.pageTitle}
            totalCount={totalCount}
            introText={currentPage.pageIntroText}
            changePage={handleChangePage}
            pageSize={pageSize}
            pageIndex={pageIndex}
            changePageSize={handleChangePageSize}
          >
            {orders.map((order: OrderDetailsModel) => {
              const title = (
                <div>
                  <div>
                    <StyledAccountKexLink
                      href={`${orderDetailsPage}?orderId=${order.externalOrderID}`}
                    >
                      {order.externalOrderID}
                    </StyledAccountKexLink>
                    {` - ${dateLabel}: ${getFormattedDate(order.orderDate)}`}
                  </div>
                </div>
              );

              return (
                <div key={order.orderId}>
                  <Accordion title={title}>
                    <OrderRowListing order={order} />
                  </Accordion>
                  <MaxDivider />
                </div>
              );
            })}
          </OrderListing>
        </>
      ) : !isLoading ? (
        <div>{noOrdersLabel}</div>
      ) : (
        <DotsWrapper>
          <Dots />
        </DotsWrapper>
      )}
    </main>
  );
}

const MaxDivider = styled(Divider, {
  w: '100%',
  h: 0.5,
  opacity: 0.5,
  m: 0,
});

const StyledAccountKexLink = styled(KexLink, {
  fs: 8,
  fontWeight: '$fw700',
  lineHeight: '28px',
  // letterSpacing: theme.letterSpacing.moreWide,
});

const DotsWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  pb: 9,
});

export default OrderHistoryPage;
