import { BaseIconStyle } from '../Icons/IconBaseStyle';
import KexIconLoader from '../../Kex/KexIconLoader';
import { css, styled } from '../../stitches.config';
import BaseButtonStyled from './BaseButtonStyle';
import BaseButtonType from './BaseButtonType';

function Button({
  onClick,
  children,
  icon,
  disabled,
  type,
  size,
  css,
  border,
  ...rest
}: BaseButtonType) {
  var Icon = icon && KexIconLoader(icon);
  return (
    <StyledButton
      size={size}
      css={css}
      color={type}
      {...(!disabled && onClick && { onClick })}
      disabled={disabled}
      border={border}
      {...rest}
    >
      {Icon && <Icon style={StyledIcon} color={type} onBackground={true} />}
      {children}
      <HoverLine color={type} />
    </StyledButton>
  );
}

const StyledIcon = css({
  mr: 2,
});

const HoverLine = styled('div', {
  h: 0.25,
  w: 'calc(100% - 6px)',
  position: 'absolute',
  b: -0.75,
  mx: 0.5,
  transition: 'all $200 ease-in-out',
  variants: {
    color: {
      primary: {
        backgroundColor: '$buttonHoverBackgroundPrimary',
      },
      secondary: {
        backgroundColor: '$buttonHoverBackgroundSecondary',
        [`& ${BaseIconStyle}`]: {
          fill: '$textSecondary',
        },
      },
      transparent: {
        backgroundColor: 'transparent',
      },
      accent: {
        backgroundColor: '$accent',
      },
    },
  },
  defaultVariants: {
    color: 'primary',
  },
});

const StyledButton = styled(BaseButtonStyled, {
  position: 'relative',
  overflow: 'hidden',
  //Set default for button
  defaultVariants: {
    color: 'primary',
    size: 'm',
  },
});

export default Button;
