import React from 'react';
import { useEffect, useState } from 'react';
import Button from '../../Atoms/Buttons/Button';
import { ArrowIcon, ChevronIcon } from '../../Atoms/Icons';
import GenericDropDown from '../../Molecules/ValueDropDown/GenericDropDown';
import { styled } from '../../stitches.config';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';
import useMedia from '../Hooks/useMedia';
import PaginationStep from './PaginationStep';

type PropTypes = {
  pageCount: number;
  totalRecords: number;
  onPageChange: (x: any) => void;
  currentPage?: number;
};

function Pagination({
  totalRecords,
  pageCount,
  onPageChange,
  currentPage: currentPageProp,
}: PropTypes) {
  const isMobile = useMedia(mediaQueryTypes.mediaMaxLarge);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const totalAmountSteps = [...Array(Math.ceil(pageCount))];

  useEffect(() => {
    if (currentPageProp !== undefined) {
      setCurrentPage(currentPageProp);
    }
  }, [currentPageProp]);

  const updatePagination = (currentPage: number) => {
    onPageChange(currentPage);
    setCurrentPage(currentPage);
  };

  const nextPage = () => {
    updatePagination(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) {
      updatePagination(currentPage - 1);
    }
  };

  const goToPage = (index: number | string) => {
    if (typeof index === 'string') {
      index = parseInt(index);
    }
    updatePagination(index);
  };

  const renderPaginationSteps = () => {
    return (
      <>
        {totalAmountSteps &&
          totalAmountSteps.map((_, index) => {
            return (
              <React.Fragment key={index}>
                <PaginationStep
                  isActive={index + 1 === currentPage}
                  onClick={() => goToPage(index + 1)}
                >
                  {index + 1}
                </PaginationStep>
              </React.Fragment>
            );
          })}
      </>
    );
  };

  return (
    <PaginationWrapper>
      {totalRecords ? (
        <>
          <StyledIconButton
            disabled={!currentPage || currentPage <= 1}
            onClick={prevPage}
          >
            <ArrowIcon color="secondary" rotateLeft />
          </StyledIconButton>
          {isMobile ? (
            <GenericDropDown
              values={totalAmountSteps}
              currentValue={currentPage?.toString()}
              setSelectedValue={(result: string) => goToPage(result)}
              isPagination
            />
          ) : (
            <StepsWrapper>{renderPaginationSteps()}</StepsWrapper>
          )}
          <StyledIconButton
            disabled={totalAmountSteps.length === currentPage}
            onClick={nextPage}
          >
            <ArrowIcon color="secondary" />
          </StyledIconButton>
        </>
      ) : null}
    </PaginationWrapper>
  );
}

const PaginationWrapper = styled('div', {
  px: 4,
  mt: 8,
  display: 'flex',
  alignItems: 'stretch',
  w: '100%',
  justifyContent: 'center',
});

const StepsWrapper = styled('div', {
  maxW: 80,
  overflow: 'auto',
  display: 'flex',
  alignItems: 'center',
});

const StyledIconButton = styled(Button, {});

const Step = styled('span', {
  mx: 6,
  color: '$grey800',
  '&:hover': {
    cursor: 'pointer',
    color: '$black',
  },
  variants: {
    isActive: {
      true: {
        fontWeight: '$fw700',
        color: '$black',
      },
    },
  },
});

export default React.memo(Pagination);
