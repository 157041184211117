import OrderDetailsModel from '../Order/Models/OrderDetailsModel.interface';
import OrderRowItem from '../Order/Models/OrderRowItem.interface';
import { useTranslationData } from '../Shared/Providers/TranslationProvider';
import { styled } from '../stitches.config';
import OrderListingProductCard from './OrderListingProductCard';

type PropType = {
  order: OrderDetailsModel;
};

function OrderRowListing({ order }: PropType) {
  const {
    'orderListing/totalsum': totalSumLabel,
    'orderListing/paymentmethod': paymentMethodLabel,
    'orderListing/customerInformation': customerInformationLabel,
    'orderListing/products': productsLabel,
    'orderListing/status': statusLabel,
  } = useTranslationData();

  return (
    <>
      <OrderInformation>
        <StyledSpan>{`${statusLabel}: ${order.status}`}</StyledSpan>
        <StyledSpan>{`${totalSumLabel}: ${order.orderGrandTotal}`}</StyledSpan>
        <StyledSpan>{`${paymentMethodLabel}: ${order.paymentMethod}`}</StyledSpan>
      </OrderInformation>
      {order.customerInfo && (
        <CustomerInformation>
          <CustomerInformationContent>
            <HeadingLabel>{customerInformationLabel}</HeadingLabel>
            <Delivery>
              <StyledSpan>{order.customerInfo.address1}</StyledSpan>
              <StyledSpan>{`${order.customerInfo.zip} ${order.customerInfo.city}`}</StyledSpan>
            </Delivery>
          </CustomerInformationContent>
        </CustomerInformation>
      )}
      <ProductItems>
        <HeadingLabel>{productsLabel}</HeadingLabel>
        {order.orderRows?.map((product: OrderRowItem) => (
          <OrderListingProductCard
            key={product.articleNumber}
            product={product}
          />
        ))}
      </ProductItems>
    </>
  );
}

const ProductItems = styled('div', {
  mt: 4,
  '@mediaMinLarge': {
    mt: 8,
  },
});

const OrderInformation = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const StyledSpan = styled('span', {
  mt: 1.25,
});

const HeadingLabel = styled('span', {
  fs: 8,
  fontWeight: '$fw700',
  lineHeight: '28px',
});

const CustomerInformation = styled('div', {
  mt: 4,
  '@mediaMinLarge': {
    display: 'flex',
  },
});

const Delivery = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const CustomerInformationContent = styled('div', {});

export default OrderRowListing;
